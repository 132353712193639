import React, { useEffect, useRef, useState } from "react";
import { ChecklistType } from "../../_App/Types/Entites/ChecklistType";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  affectationChecklistByMachineGroup,
  checklistDate,
  ChecklistResults,
  checklistsCuratifGroupedByGroupe,
  findAffectationChecklist,
  findAllChecklists,
  findChecklistResult,
  rebutTech,
  rebutTL,
} from "../../_App/Redux/Slices/checklists/checklistSlice";
import { findMachineById } from "../../_App/Redux/Slices/machines/machineSlice";
import { MachineType } from "../../_App/Types/Entites/MachineType";
import Loader from "../../_Layouts/Loader/Loader";
import { findOdfByCode } from "../../_App/Redux/Slices/odfs/odfSlice";
import { OdfType } from "../../_App/Types/Entites/ODFType";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ChecklistHistoryTooltip from "./ChecklistHistoryTooltip";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";

const ListChecklistsCuratif = () => {
  const { odfCode, machineCode } = useParams();
  const [machine, setMachine] = useState<MachineType>();
  const [odf, setOdf] = useState<OdfType>();
  const rebutTeamLeader = useAppSelector(rebutTL);
  const rebutTechnicien = useAppSelector(rebutTech);
  const date = useAppSelector(checklistDate);
  const componentRef = useRef<HTMLDivElement>(null);
  const [entete, setEntete] = useState<any>();

  
  // };

  const handlePrint = () => {
    // Access the hidden iframe and trigger its print method
    const printFrame = document.getElementById(
      "print-frame"
    ) as HTMLIFrameElement | null;
    if (printFrame) {
      printFrame.contentWindow?.print();
    }
  };

  function extractTime(timestamp: any) {
    const date = new Date(timestamp);

    // Extracting time components
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Formatting time as string
    const time = `${hours}:${minutes}:${seconds}`;

    return time;
  }

  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();
  const affectations = useAppSelector(affectationChecklistByMachineGroup);

  const checklists = useAppSelector(checklistsCuratifGroupedByGroupe);
  const results = useAppSelector(ChecklistResults);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (machineCode && odfCode) {
          setLoading(true);

          const resMachine = await dispatch(findMachineById(machineCode));
          const resOdf = await dispatch(findOdfByCode(odfCode));
          const resAff = await dispatch(findAffectationChecklist(null));
          if (resOdf.payload) setOdf(resOdf.payload);
          if (resMachine.payload) {
            setMachine(resMachine.payload);
            dispatch(findAllChecklists(null));
            dispatch(findChecklistResult(odfCode));
          }
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, machineCode]);

  return (
    <div>
      {!loading &&
      machine &&
      affectations &&
      Object.keys(affectations).length > 0 &&
      Object.keys(checklists).length > 0 &&
      machine.groupe in affectations ? (
        <div id="tableFooter" className="col-lg-12 col-12 layout-spacing">
          <div className="statbox widget box box-shadow" ref={componentRef}>
            <div className="widget-header">
              <div className="row">
                <div
                  className="col-xl-12 col-md-12 col-sm-12 col-12"
                  style={{ padding: "0px 4px" }}
                >
                  <table className="table table-bordered table-hover table-condensed mb-0 mt-0">
                    <thead>
                      <tr className="checklist-entete">
                        <th>
                          <img
                            style={{ height: "60px" }}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/assets/img/ecopack_logo.png"
                            }
                            alt=""
                          />
                        </th>
                        <th className="text-center">
                          {" "}
                          {"Checklist demarrage ODP - MK" +
                            machine?.groupe}{" "}
                        </th>
                        <th>-</th>
                      </tr>
                      <tr
                        className="checklist-entete"
                        style={{ borderTop: "1px solid #000" }}
                      >
                        <th>Date : {entete?.date} </th>
                        <th>Heure : {entete?.heure}</th>
                        <th>N° ODP : {odfCode}</th>
                        <th>Article : {machine.nom} </th>
                        <th>MK : </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>{" "}
            <div
              className="widget-content widget-content-area"
              style={{ padding: 0 }}
            >
              <div className="table-responsive">
                <table className="table table-bordered table-hover table-condensed mb-0 mt-0">
                  {machine &&
                    affectations &&
                    Object.keys(affectations).length > 0 &&
                    Object.keys(checklists).length > 0 &&
                    machine.groupe in affectations &&
                    affectations[machine.groupe].map((affectation, index1) => (
                      <React.Fragment key={index1}>
                        {checklists[affectation.groupe_checklist] ? (
                          <thead>
                            <tr>
                              <th colSpan={3} style={{ textAlign: "center" }}>
                                {" "}
                                {affectation.groupe_checklist}{" "}
                              </th>
                              <th className="text-center">Resultat</th>
                              <th className="text-center">Utilisateur</th>

                              <th className="text-center">Heure</th>
                              <th>Remarque</th>
                              <th>Dérogateur</th>
                              <th>Heure Dérogation</th>
                            </tr>
                          </thead>
                        ) : null}

                        <tbody>
                          {affectation.groupe_checklist in checklists &&
                            checklists[affectation.groupe_checklist].map(
                              (item, index) => (
                                <tr key={index}>
                                  <td onClick={() => console.log(item)}>
                                    {" "}
                                    {index + 1}{" "}
                                  </td>
                                  <td
                                    colSpan={2}
                                    onClick={() => console.log(item.code)}
                                  >
                                    {" "}
                                    {item.libelle}{" "}
                                  </td>
                                  <td
                                    className="text-center"
                                    onClick={() =>
                                      console.log(item.ChecklistResults)
                                    }
                                  >
                                    <ul className="table-controls">
                                      {results &&
                                      results[item.code] &&
                                      results[item.code][0] ? (
                                        results[item.code][0].derogation ===
                                        1 ? (
                                          results[item.code].some(
                                            (item) => item.derogation === 0
                                          ) ? (
                                            <>
                                              <li
                                                data-tooltip-id={`my-tooltip-${index}`}
                                                onClick={() =>
                                                  console.log(
                                                    results[item.code]
                                                  )
                                                }
                                              >
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-x-circle text-danger"
                                                  >
                                                    <circle
                                                      cx="12"
                                                      cy="12"
                                                      r="10"
                                                    ></circle>
                                                    <line
                                                      x1="15"
                                                      y1="9"
                                                      x2="9"
                                                      y2="15"
                                                    ></line>
                                                    <line
                                                      x1="9"
                                                      y1="9"
                                                      x2="15"
                                                      y2="15"
                                                    ></line>
                                                  </svg>
                                                </span>
                                              </li>
                                              <li
                                                onClick={() =>
                                                  console.log(
                                                    results[item.code]
                                                  )
                                                }
                                              >
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-check-circle text-success"
                                                  >
                                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                  </svg>
                                                </span>
                                              </li>
                                              <ReactTooltip
                                                id={`my-tooltip-${index}`}
                                                place="right"
                                                style={{
                                                  backgroundColor: "#fff",
                                                  color: "#222",
                                                }}
                                              >
                                                <div className="mt-container mx-auto">
                                                  <div className="timeline-alter">
                                                    {results[item.code]?.map(
                                                      (result, index2) => (
                                                        <div
                                                          className="item-timeline"
                                                          key={index2}
                                                        >
                                                          <div className="t-time">
                                                            <p className="">
                                                              {
                                                                result.heure_debut
                                                              }
                                                            </p>
                                                          </div>
                                                          <div className="t-img">
                                                            {result.result ===
                                                            "1" ? (
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                className="feather feather-check-circle text-success"
                                                              >
                                                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                              </svg>
                                                            ) : (
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                className="feather feather-x-circle text-danger"
                                                              >
                                                                <circle
                                                                  cx="12"
                                                                  cy="12"
                                                                  r="10"
                                                                ></circle>
                                                                <line
                                                                  x1="15"
                                                                  y1="9"
                                                                  x2="9"
                                                                  y2="15"
                                                                ></line>
                                                                <line
                                                                  x1="9"
                                                                  y1="9"
                                                                  x2="15"
                                                                  y2="15"
                                                                ></line>
                                                              </svg>
                                                            )}
                                                          </div>
                                                          {/* <div className="t-meta-time">
                                                            <p className="">
                                                              
                                                            </p>
                                                          </div> */}

                                                          <div className="t-text">
                                                            <p>
                                                              {" "}
                                                              {
                                                                result.motif
                                                              }{" "}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </ReactTooltip>
                                            </>
                                          ) : results[item.code][0]
                                              .derogateur ? (
                                            <>
                                              <li>
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-x-circle text-danger"
                                                  >
                                                    <circle
                                                      cx="12"
                                                      cy="12"
                                                      r="10"
                                                    ></circle>
                                                    <line
                                                      x1="15"
                                                      y1="9"
                                                      x2="9"
                                                      y2="15"
                                                    ></line>
                                                    <line
                                                      x1="9"
                                                      y1="9"
                                                      x2="15"
                                                      y2="15"
                                                    ></line>
                                                  </svg>
                                                </span>
                                              </li>
                                              <li>
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-check-circle text-success"
                                                  >
                                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                  </svg>
                                                </span>
                                              </li>
                                            </>
                                          ) : (
                                            <li>
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-check-circle text-success"
                                                >
                                                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                </svg>
                                              </span>
                                            </li>
                                          )
                                        ) : (
                                          <li>
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-x-circle text-danger"
                                              >
                                                <circle
                                                  cx="12"
                                                  cy="12"
                                                  r="10"
                                                ></circle>
                                                <line
                                                  x1="15"
                                                  y1="9"
                                                  x2="9"
                                                  y2="15"
                                                ></line>
                                                <line
                                                  x1="9"
                                                  y1="9"
                                                  x2="15"
                                                  y2="15"
                                                ></line>
                                              </svg>
                                            </span>
                                          </li>
                                        )
                                      ) : null}
                                    </ul>
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {results &&
                                    results[item.code] &&
                                    results[item.code][0]
                                      ? results[item.code][0].nom_user
                                      : ""}{" "}
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {results &&
                                    results[item.code] &&
                                    results[item.code][0]
                                      ? results[item.code][0].heure_debut
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {results &&
                                    results[item.code] &&
                                    results[item.code][0]
                                      ? results[item.code][0].commentaire
                                      : ""}{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    {results &&
                                    results[item.code] &&
                                    results[item.code][0]
                                      ? results[item.code][0].User?.nom
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {results &&
                                    results[item.code] &&
                                    results[item.code][0]
                                      ? results[item.code][0].heure_derogation
                                      : ""}{" "}
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </React.Fragment>
                    ))}
                  <tfoot>
                    <tr>
                      <th colSpan={2}>Heure de démarrage</th>
                      <th colSpan={2}>Rebut / Démarrage / Maintenance </th>
                      <th colSpan={2}>Rebut / Démarrage / Production</th>
                      {/* <th colSpan={2}>Rebut Technicien </th>
                      <th colSpan={2}>Rebut Team Leader</th> */}
                      <th>Team Leader</th>
                      <th>Contrôleur qualité</th>
                    </tr>
                    <tr>
                      <th
                        colSpan={2}
                        style={{ color: "#fff", border: "1px solid #000" }}
                      >
                        ............
                      </th>
                      <th
                        colSpan={2}
                        style={{ color: "#000", border: "1px solid #000" }}
                      >
                        {rebutTechnicien}
                      </th>
                      <th
                        colSpan={2}
                        style={{ color: "#000", border: "1px solid #000" }}
                      >
                        {rebutTeamLeader}
                      </th>
                      <th style={{ color: "#fff", border: "1px solid #000" }}>
                        ............
                      </th>
                      <th style={{ color: "#fff", border: "1px solid #000" }}>
                        ............
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                onClick={handlePrint}
                className="btn-info"
                style={{ float: "right" }}
              >
                Imprimer
              </Button>
            )}
            content={() => componentRef.current}
          />
          {/* Hidden iframe for printing */}
          <iframe
            id="print-frame"
            title="print-frame"
            style={{ display: "none" }}
            onLoad={() => handlePrint()}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ListChecklistsCuratif;
