import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../_App/Redux/hooks";
import { downtimes, stats } from "../../_App/Redux/Slices/machines/machineSlice";

// Define types for your data


interface TableMachinesProps {
  setMergedData: any;
}

const TableMachines: React.FC<TableMachinesProps> = ({ setMergedData }) => {
  const machinesStats = useAppSelector(stats);
  const inactiveTimes = useAppSelector(downtimes);

  // Step 1: Merge the machinesStats and inactiveTimes
  const mergedData = useMemo(() => {
    return machinesStats.map((machine:any) => {
      const inactive = inactiveTimes.find(
        (inactive:any) => inactive.machine_code === machine.code
      );

      return {
        ...machine,
        total_inactif_time: inactive
          ? Math.round(parseFloat(inactive.total_inactif_time) * 60) // Convert to minutes and round to the nearest integer
          : "N/A",
      };
    });
  }, [machinesStats, inactiveTimes]);

  useEffect(() => {
    if (mergedData && mergedData.length) setMergedData(mergedData);
  }, [mergedData, setMergedData]);

  return (
    <table id="style-3" className="table style-3 table-hover">
      <thead>
        <tr>
          <th onClick={() => console.log(mergedData)}>Machine</th>
          <th>ODPs</th>
          <th>Interventions</th>
          <th>Tickets</th>
          <th>{"Temps d'Arrêt (minutes)"}</th> {/* Update to minutes */}
        </tr>
      </thead>
      <tbody>
        {mergedData.length > 0 ? (
          mergedData.map((machine:any) => (
            <tr key={machine.code}>
              <td>{machine.code}</td>
              <td>{machine.planningCount}</td>
              <td>{machine.interventionCount}</td>
              <td>{machine.supportTicketCount}</td>
              <td>
                {machine.total_inactif_time !== "N/A"
                  ? machine.total_inactif_time // Display the rounded minutes value
                  : "_"}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5}>No data available</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TableMachines;
