import React, { useEffect, useState } from "react";
import Navbar from "../../_Layouts/Navbar/Navbar";
import TableMachines from "../../Components/Analyses/TableMachines";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  consommationPiecesDeRechanges,
  findMachineDowntime,
  findMachinesStats,
  findNumberPanne,
  machineRepairingTime,
  MachinesProductsConsumes,
  mtbf_mttr,
  MTBF_MTTR,
  numberPanneMachines,
  repairingTime,
} from "../../_App/Redux/Slices/machines/machineSlice";
import PieChartComponent from "../../Components/Analyses/MachinesPie";
import {
  findTicketsStats,
  statsByPanne,
} from "../../_App/Redux/Slices/tickets/ticketSlice";
import moment from "moment";
import TableTickets from "../../Components/Analyses/ticketsStatsTable";
import TicketPie from "../../Components/Analyses/ticketPie";

import RepairTimePieChart from "../../Components/Analyses/MachineRepairTime";
import InterventionPieChart from "../../Components/Analyses/InterventionsGroups";
import HorizontalBarChart from "../../Components/Analyses/MachinesInterventions";

import InterventionsChart from "../../Components/Analyses/TechniciensInterventions";
import GanttChart from "../../Components/Analyses/GanttDiagram";
import {
  groupsCount,
  interventionsGroupsCount,
  machineInterventionsCount,
  machinesInterventions,
  technicienInterventions,
  technicienInterventionsCount,
} from "../../_App/Redux/Slices/interventions/interventionSlice";
import ConsommationProduits from "../../Components/Analyses/MachinesProductsConsume";
import Loader from "../../_Layouts/Loader/Loader";
import RapportMTBF_MTTR from "../../Components/Analyses/RapportMTBF_MTTR";

const MachineAnalysesPage = () => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [singleDay, setSingleDay] = useState<any>(
    moment().format("YYYY-MM-DD")
  );
  const Pannes = useAppSelector(statsByPanne);
  const MachinePannesNumber = useAppSelector(numberPanneMachines);
  const machineRepairTime = useAppSelector(machineRepairingTime);
  const interventionsGroups = useAppSelector(interventionsGroupsCount);
  const machinesInterventionsNumber = useAppSelector(machineInterventionsCount);
  const techsInterventions = useAppSelector(technicienInterventionsCount);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(findMachinesStats(null)),
        dispatch(findMachineDowntime({ start: startDate, end: endDate })),
        dispatch(findNumberPanne({ start: startDate, end: endDate })),
        dispatch(
          consommationPiecesDeRechanges({ start: startDate, end: endDate })
        ),
        dispatch(repairingTime({ start: startDate, end: endDate })),
        dispatch(groupsCount({ start: startDate, end: endDate })),
        dispatch(machinesInterventions({ start: startDate, end: endDate })),
        dispatch(findTicketsStats({ start: startDate, end: endDate })),
        dispatch(MTBF_MTTR({ start: startDate, end: endDate })),
      ]);
      setLoading(false);
    };

    fetchData();
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    const fetchTechnicienInterventions = async () => {
      setLoading(true);
      await dispatch(technicienInterventions(singleDay));
      setLoading(false);
    };

    fetchTechnicienInterventions();
  }, [dispatch, singleDay]);

  const [mergedData, setMergedData] = useState();

  return loading ? (
    <Loader />
  ) : (
    <div className="admin-data-content container-fluid">
      <Navbar titre={"Analyses"} />
      <div className="row date-filter-row">
        <div className="col-md-3 col-6 pb-2">
          <label htmlFor="start-date"></label>
          <input
            className="date-input form-control"
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) =>
              setStartDate(
                moment(e.currentTarget.value, "YYYY-MM-DD").format("YYYY-MM-DD")
              )
            }
          />
        </div>
        <div className="col-md-3 col-6 pb-2">
          <label htmlFor="end-date"></label>
          <input
            className="date-input form-control"
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) =>
              setEndDate(
                moment(e.currentTarget.value, "YYYY-MM-DD").format("YYYY-MM-DD")
              )
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-12 layout-spacing">
          <div className="widget widget-one">
            <div className="widget-content">
              <RepairTimePieChart data={machineRepairTime} />
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12 layout-spacing">
          <div className="widget widget-one">
            <div className="widget-content">
              <InterventionPieChart data={interventionsGroups} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-6 layout-spacing">
          <div className="widget widget-one">
            <div className="widget-content">
              <HorizontalBarChart data={machinesInterventionsNumber} />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-6 layout-spacing">
          <div className="widget widget-one">
            <h6 className="widget-title">
              Consommation des piéces de rechange
            </h6>
            <div className="widget-content">
              <ConsommationProduits />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="widget widget-one">
          <div className="widget-content">
            <GanttChart
              singleDay={singleDay}
              setSingleDay={setSingleDay}
              data={techsInterventions}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-12 layout-spacing">
          <div className="widget widget-one">
            <div className="widget-content">
              <PieChartComponent data={MachinePannesNumber} />
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12 layout-spacing">
          <div className="widget widget-one">
            <div className="widget-content">
              <TicketPie data={Pannes} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-12 layout-spacing">
          <div className="widget widget-one">
            <h6 className="widget-title">Machines</h6>
            <div className="widget-content">
              <TableMachines setMergedData={setMergedData} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-12 layout-spacing">
          <div className="widget widget-one">
            <h6 className="widget-title">Tickets</h6>
            <div className="widget-content">
              <TableTickets setMergedData={setMergedData} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-12 layout-spacing">
          <div className="widget widget-one">
            <h6 className="widget-title">Moyenne des pannes et réparations </h6>
            <div className="widget-content">
              <RapportMTBF_MTTR setMergedData={setMergedData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineAnalysesPage;
