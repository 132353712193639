import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Form, Button } from "react-bootstrap";
import * as XLSX from "xlsx"; // Import xlsx
import {
  MachinesProductsConsumes,
  mtbf_mttr,
} from "../../_App/Redux/Slices/machines/machineSlice";

const ConsommationProduits = () => {
  const dispatch = useAppDispatch();
  const consommationProductsParMAchines = useAppSelector(
    MachinesProductsConsumes
  );

  const [currentPage, setCurrentPage] = useState(1);
  const machinesPerPage = 15;
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = consommationProductsParMAchines?.filter(
    (item: any) =>
      item.machine_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.libelle?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const indexOfLastMachine = currentPage * machinesPerPage;
  const indexOfFirstMachine = indexOfLastMachine - machinesPerPage;
  const currentMachines = filteredData?.slice(
    indexOfFirstMachine,
    indexOfLastMachine
  );
  const totalPages = Math.ceil(filteredData?.length / machinesPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const exportToExcel = () => {
    const dataToExport = filteredData?.map((machine: any) => ({
      machine: machine.machine_code,
      produit: machine.libelle,
      quantité: machine.total_quantite,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "consommationProduits");
    XLSX.writeFile(workbook, "consommation_pieces_de_rechange.xlsx");
  };

  return (
    <>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="col-10">
          {" "}
          <Form.Group controlId="formSearch">
            <Form.Control
              type="text"
              placeholder="Recherche par machine ou produit ..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          {" "}
          <Button
            variant="success"
            onClick={exportToExcel}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export
          </Button>
        </div>
      </div>

      <table id="style-3" className="table style-3 table-hover">
        <thead>
          <tr>
            <th>Machine</th>
            <th>Produit</th>
            <th>Quantité</th>
          </tr>
        </thead>
        <tbody>
          {currentMachines?.length > 0 ? (
            <>
              {currentMachines?.map((machine: any, index: any) => (
                <tr key={index}>
                  <td>{machine.machine_code}</td>
                  <td>{machine.libelle}</td>
                  <td>{machine.total_quantite}</td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={10}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination" style={{ overflow: "auto", margin: "5px" }}>
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className="btn"
        >
          Préc.
        </button>

        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            className={currentPage === i + 1 ? "btn active" : "btn"}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}

        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className="btn"
        >
          Suiv.
        </button>
      </div>
    </>
  );
};

export default ConsommationProduits;
