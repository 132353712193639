import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { TicketType } from "../../../Types/Entites/TicketType";

export const findTickets = createAsyncThunk(
  "ticket/findTickets",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`ticket/findTickets`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findTicketsStats = createAsyncThunk(
  "ticket/ticketsStats",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `ticket/ticketsStats/${data.start}/${data.end}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findTicketsImage = createAsyncThunk(
  "ticket/findticketsImages",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`ticket/findticketsImages`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateTicket = createAsyncThunk(
  "ticket/updateTicket",
  async ({ id, newData }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`ticket/updateTicket/${id}`, newData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const affectUser = createAsyncThunk(
  "ticket/affectUser",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`ticket/affectUser/${id}`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findTicketByCode = createAsyncThunk(
  "ticket/ticket",
  async (code: any, { rejectWithValue }) => {
    try {
      const response = await axios.get(`ticket/ticket/${code}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const sendAffectationTicket = createAsyncThunk(
  "ticket/notif",
  async ({ role, text }: any, { rejectWithValue }) => {
    try {
      console.log(role, text);
      const response = await axios.post(`ticket/notif/${role}`, text);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface Tickets {
  ticketsList: TicketType[];
  ticketsGroupedByDate: { [date: string]: any[] };
  ticketsGroupedByCode: { [code: string]: any[] };
  imagesGroupedByTicket: { [ticket: string]: any[] };
  stats: any;
  statsGroupedByPanne: { [panne: string]: any[] };
  currentTickets: TicketType[];
}

const initialState: Tickets = {
  ticketsList: [],
  ticketsGroupedByDate: {},
  imagesGroupedByTicket: {},
  ticketsGroupedByCode: {},
  stats: [],
  statsGroupedByPanne: {},
  currentTickets: [],
};

export const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findTickets.fulfilled, (state, action) => {
      const grouped = _.groupBy(action.payload, "date");
      const groupedByCode = _.groupBy(action.payload, "code");
      const currenttickets = action.payload.filter(
        (ticket: TicketType) => ticket.cloture === 0
      );

      return {
        ...state,
        ticketsList: action.payload,
        ticketsGroupedByDate: grouped,
        ticketsGroupedByCode: groupedByCode,
        currentTickets: currenttickets,
      };
    });
    builder.addCase(findTicketsImage.fulfilled, (state, action) => {
      const grouped = _.groupBy(action.payload, "code");
      return {
        ...state,
        imagesGroupedByTicket: grouped,
      };
    });
    builder.addCase(findTicketsStats.fulfilled, (state, action) => {
      const filteredPayload = action.payload.filter(
        (item: any) => item.type === "DEFAUT" || item.type === "PANNE"
      );

      const grouped = _.groupBy(filteredPayload, "panne");
      return {
        ...state,
        stats: action.payload,
        statsGroupedByPanne: grouped,
      };
    });

    builder.addCase(affectUser.fulfilled, (state, action) => {
      const index = state.ticketsList.findIndex(
        (ticket) => ticket.id === action.payload.id
      );

      if (index !== -1) {
        const updatedListTickets = [...state.ticketsList];
        updatedListTickets[index] = action.payload;

        return {
          ...state,
          ticketsList: updatedListTickets,
        };
      }
      return state;
    });
    builder.addCase(updateTicket.fulfilled, (state, action) => {
      const index = state.currentTickets.findIndex(
        (ticket) => ticket.id === action.payload.id
      );

      if (index !== -1) {
        // Directly update the ticket in the state without creating a new array
        state.currentTickets[index] = action.payload;
      }
      // No need to return state as Immer automatically handles immutability
    });
  },
});

export const TicketsList = (state: RootState) => state.tickets.ticketsList;
export const statsTickets = (state: RootState) => state.tickets.stats;
export const statsByPanne = (state: RootState) =>
  state.tickets.statsGroupedByPanne;

export const TicketsPerDates = (state: RootState) =>
  state.tickets.ticketsGroupedByDate;
export const TicketsByCode = (state: RootState) =>
  state.tickets.ticketsGroupedByCode;
export const ImagesTickets = (state: RootState) =>
  state.tickets.imagesGroupedByTicket;
export const CurrentTicket = (state: RootState) => state.tickets.currentTickets;
export default ticketSlice.reducer;
