import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { MachineType } from "../../../Types/Entites/MachineType";
import { MachineDataType } from "../../../Types/Forms/MachineDataType";
import _ from "lodash";
import {
  MachinePlanningType,
  ProdPlanningType,
} from "../../../Types/Entites/PlanningType";

export const importPrograms = createAsyncThunk(
  "progs/insertProdPlanning",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("progs/insertProdPlanning", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const createPlaningItem = createAsyncThunk(
  "progs/createPlaning",
  async (data: MachinePlanningType, { rejectWithValue }) => {
    try {
      const response = await axios.post("progs/createPlaning", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const deletePlaningItem = createAsyncThunk(
  "progs/deletePlaningItem",
  async (code: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`progs/deletePlaningItem/${code}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findProgsProd = createAsyncThunk(
  "progs/findProgProdToday",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`progs/findProgProdToday`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findMachineProgs = createAsyncThunk(
  "progs/findMachineProgs",
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`progs/findMachineProgs/${code}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findProdPlanningEvents = createAsyncThunk(
  "progs/findProdPlanningEventsApi",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`progs/findProdPlanningEventsApi`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findMachinesPlanningEvents = createAsyncThunk(
  "progs/findMachinesPlanningEventsApi",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`progs/findMachinesPlanningEventsApi`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const updatePlaningMachine = createAsyncThunk(
  "progs/updatePlaning",
  async ({ id, newData }: any, { rejectWithValue }) => {
    try {
      console.log("id:", id);
      console.log("newData:", newData);
      const response = await axios.put(`progs/updatePlaning/${id}`, newData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateProgramProd = createAsyncThunk(
  "progs/updateProgramProd",
  async ({ id, newData }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `progs/updateProgramProd/${id}`,
        newData
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

interface ProgProd {
  prodPlanningsByDate: { [date: string]: ProdPlanningType[] };
  machineProdPlanningsByDate: { [date: string]: ProdPlanningType[] };
  todayMachines: { machine: MachineType[]; odf_code: string }[] | {};
  prodPlanings: ProdPlanningType[];
  machinePlaning: MachinePlanningType[];
}

const initialState: ProgProd = {
  prodPlanningsByDate: {},
  machineProdPlanningsByDate: {},
  todayMachines: {},
  prodPlanings: [],
  machinePlaning: [],
};

export const PlanningsSlice = createSlice({
  name: "program-prod",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findProgsProd.fulfilled, (state, action) => {
      if (action.payload.length) {
        let machines: any[] = [];
        const groupedByMachines = _.groupBy(action.payload, "machine_code");
        const groupedByDate = _.groupBy(action.payload, "date_prod");

        Object.keys(groupedByMachines).forEach((key) => {
          const value = groupedByMachines[key];
          machines.push({
            machine: value[0].Machine,
            odf_code: value[0].odf_code,
          });
        });

        return {
          ...state,
          prodPlanningsByDate: groupedByDate,
          todayMachines: machines,
        };
      } else {
        return {
          ...state,
          todayProg: action.payload,
        };
      }
    });
    builder.addCase(findMachineProgs.fulfilled, (state, action) => {
      const groupedByDate = _.groupBy(action.payload, "date_prod");

      return {
        ...state,
        machineProdPlanningsByDate: groupedByDate,
      };
    });
    builder.addCase(findProdPlanningEvents.fulfilled, (state, action) => {
      return {
        ...state,
        prodPlanings: action.payload,
      };
    });
    builder.addCase(findMachinesPlanningEvents.fulfilled, (state, action) => {
      return {
        ...state,
        machinePlaning: action.payload,
      };
    });
    builder.addCase(updatePlaningMachine.fulfilled, (state, action) => {
      console.log(action.payload);
      return {
        ...state,
      };
    });
    builder.addCase(updateProgramProd.fulfilled, (state, action) => {
      const index = _.findIndex(state.prodPlanings, {
        id: action.payload.id,
      });
      if (index !== -1) {
        const updatedListPrograms = [...state.prodPlanings];
        updatedListPrograms[index] = {
          ...updatedListPrograms[index],
          ...action.payload, // Merge the updated data from the payload
        };
        return {
          ...state,
          prodPlanings: updatedListPrograms,
        };
      }
      return state;
    });
  },
});

export const ProgsProd = (state: RootState) =>
  state.plannings.prodPlanningsByDate;
export const MachineProgsByDate = (state: RootState) =>
  state.plannings.machineProdPlanningsByDate;
export const TodayMachines = (state: RootState) =>
  state.plannings.todayMachines;
export const ProdPanning = (state: RootState) => state.plannings.prodPlanings;
export default PlanningsSlice.reducer;
