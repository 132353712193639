import React, { useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ListMachines } from "../../_App/Redux/Slices/machines/machineSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { MachinePlanningType } from "../../_App/Types/Entites/PlanningType";
import { createPlaningItem } from "../../_App/Redux/Slices/plannings/planningsSlice";
import Swal from "sweetalert2";

const PreventifeForm = ({
  showModal,
  handleClose,
  date,
  reload,
  setReload,
}: any) => {
  const machines = useAppSelector(ListMachines);
  const dispatch = useAppDispatch();

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MachinePlanningType>();
  useEffect(() => {
    reset({
      machine_code: "",
      mois: date?.month,
      annee: date?.year,
      date: new Date(),
      user_code: null,
    });
  }, [date, showModal]);

  const onSubmit: SubmitHandler<MachinePlanningType> = async (data) => {
    try {
      // Dispatch the action to create a planning item
      const res = await dispatch(createPlaningItem(data));

      if (res.payload.success) {
        // Success: Show a SweetAlert success message in French
        Swal.fire({
          title: "Succès!",
          text: "L'élément de planification a été créé avec succès.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          setReload(!reload);
          handleClose();
        });
      } else {
        // Failure: Show a SweetAlert error message in French
        Swal.fire({
          title: "Erreur!",
          text: "Échec de la création de l'élément de planification. Veuillez réessayer.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      // Handle unexpected errors
      Swal.fire({
        title: "Erreur!",
        text: "Une erreur inattendue s'est produite. Veuillez réessayer.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un programme préventif</Modal.Title>
      </Modal.Header>{" "}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group controlId="machineSelect">
            <Form.Label>Choisir la machine</Form.Label>
            <Form.Control
              as="select"
              {...register("machine_code", {
                required: "La machine est requise",
              })}
            >
              {machines.map((machine: any) => (
                <option key={machine.code} value={machine.code}>
                  {machine.code}
                </option>
              ))}
            </Form.Control>
            {errors.machine_code && (
              <span className="text-danger">{errors.machine_code.message}</span>
            )}
          </Form.Group>

          {/* You can add more fields here if necessary */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Ajouter
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>{" "}
      </Form>
    </Modal>
  );
};

export default PreventifeForm;
