// Inside UsersPage.jsx
import React, { useEffect, useState } from "react";
import Users from "../../Components/Users/Users";
import Navbar from "../../_Layouts/Navbar/Navbar";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import Ticket from "../../Components/Tickets/Ticket";
import moment from "moment"; // Import moment library for date manipulation
import {
  findTickets,
  findTicketsImage,
  ImagesTickets,
  TicketsList,
} from "../../_App/Redux/Slices/tickets/ticketSlice";
import { TicketType } from "../../_App/Types/Entites/TicketType";
import {
  findIntervention,
  findinterventionImages,
} from "../../_App/Redux/Slices/interventions/interventionSlice";
import FicheInterventionModal from "../../Components/Interventions/FicheInterventionModal";
import { InterventionType } from "../../_App/Types/Entites/InterventionType";
import Loader from "../../_Layouts/Loader/Loader";

const HistoriqueNotifications = () => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(moment().startOf("day")); // Default to start of today
  const [endDate, setEndDate] = useState<any>(moment().endOf("day")); // Default to end of today
  const [filteredNotifications, setFilteredNotifications] = useState<any>([]);

  const tickets = useAppSelector(TicketsList);
  const images = useAppSelector(ImagesTickets);

  const [ShowModal, setShowModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<TicketType | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const filtered = tickets.filter(
      (ticket) =>
        moment(ticket.date.toString().split("T")[0]).isSameOrAfter(
          startDate,
          "day"
        ) &&
        moment(ticket.date.toString().split("T")[0]).isSameOrBefore(
          endDate,
          "day"
        )
    );
    setFilteredNotifications(filtered);
  }, [startDate, endDate, tickets, dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(findTickets(null));
        const resINT = await dispatch(findIntervention(null));
        const resImagesTKT = await dispatch(findTicketsImage(null));
        const resImagesINT = await dispatch(findinterventionImages(null));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const [showIntervention, setShowIntervention] = useState(false);
  const [selectedIntervention, setSelectedIntervention] = useState<
    InterventionType | undefined
  >(undefined);
  return !loading ? (
    <div>
      <Navbar titre={"Tickets"} />
      <Ticket
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        selectedTicket={selectedTicket}
        setSelectedTicket={setSelectedTicket}
        setSelectedIntervention={setSelectedIntervention}
        setShowIntervention={setShowIntervention}
      />
      <FicheInterventionModal
        ShowModal={showIntervention}
        setShowModal={setShowIntervention}
        intervention={selectedIntervention}
        setIntervention={setSelectedIntervention}
        stop={true}
      />
      <div className="widget-content searchable-container list">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 filtered-list-search layout-spacing align-self-center mb-0 pb-2">
            <form className="form-inline my-2 my-lg-0">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-search"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input
                  type="text"
                  className="form-control product-search"
                  id="input-search"
                  placeholder="Search"
                />
              </div>
            </form>
          </div>
          <div
            className="col-3 pb-2"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="start-date"></label>
            <input
              className="date-input"
              type="date"
              id="start-date"
              value={startDate.format("YYYY-MM-DD")}
              onChange={(e) =>
                setStartDate(moment(e.currentTarget.value, "YYYY-MM-DD"))
              }
            />
          </div>
          <div
            className="col-3 pb-2"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="end-date"></label>
            <input
              className="date-input"
              type="date"
              id="end-date"
              value={endDate.format("YYYY-MM-DD")}
              onChange={(e) =>
                setEndDate(moment(e.currentTarget.value, "YYYY-MM-DD"))
              }
            />
          </div>
        </div>

        <div className="searchable-items list">
          <div className="items items-header-section">
            <div className="item-content">
              <div className="col-3">
                <h4>Type</h4>
              </div>
              <div className="user-email col-2">
                <h4>Machine / ODF</h4>
              </div>
              <div className="user-location col-2">
                <h4>Date</h4>
              </div>
              <div className="user-phone col-2">
                <h4>Affecté a</h4>
              </div>
              <div className="user-phone col-2">
                <h4>Affectation</h4>
              </div>
              <div className="user-phone col-1">
                <h4>Cloturée</h4>
              </div>
            </div>
          </div>
          {tickets &&
            filteredNotifications &&
            filteredNotifications.map((item: TicketType, index: any) => (
              <div
                className="items"
                key={index}
                onClick={() => {
                  setSelectedTicket(item);
                  setShowModal(true);
                  console.log(item.code);
                }}
              >
                <div
                  className="item-content"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="user-profile col-3">
                    {" "}
                    <img
                      className="ml-0"
                      src={
                        images[item.code]
                          ? images[item.code][0].path
                          : "assets/assets/img/no-image.jpg"
                      }
                      alt="avatar"
                    />
                    <div className="user-meta-info">
                      <p className="user-name" data-name="Alan Green">
                        {item.type + " - " + item.code}
                      </p>
                      <p className="user-work" data-occupation="Web Developer">
                        {item.CreatorUser?.nom + " " + item.CreatorUser?.prenom}
                      </p>
                    </div>
                  </div>
                  <div className="user-email col-2">
                    <p className="usr-email-addr" data-email="alan@mail.com">
                      {item.Machine?.nom + " / " + item.odf_code}
                    </p>
                  </div>
                  <div className="user-email col-2">
                    <p className="usr-email-addr" data-email="alan@mail.com">
                      {item.date.toString().split("T")[0]}
                    </p>
                  </div>
                  <div className="user-location col-2">
                    <p className="usr-location" data-location="Boston, USA">
                      {item.UserAffectedTo
                        ? item.UserAffectedTo?.nom +
                          " " +
                          item.UserAffectedTo?.prenom
                        : null}
                    </p>
                  </div>
                  <div className="user-phone col-2">
                    <p className="usr-ph-no" data-phone="+1 (070) 123-4567">
                      {item.date_affectation?.toString().split("T")[0]}
                    </p>
                  </div>
                  <div className="user-phone col-1">
                    <p className="usr-ph-no" data-phone="+1 (070) 123-4567">
                      {item.cloture ? "Oui" : "Non"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default HistoriqueNotifications;
