import React, { useState } from "react";
import Swal from "sweetalert2";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { deletePlaningItem } from "../../_App/Redux/Slices/plannings/planningsSlice";
import PreventifeForm from "./PreventifeForm";

// Type d'événement
interface Event {
  id: number;
  title: string;
  month: number;
  year: number;
  color?: string;
  description?: string;
  data: any;
}

interface YearlyCalendarProps {
  events: Event[];
  reload: boolean;
  setReload: any;
}

const YearlyCalendar: React.FC<YearlyCalendarProps> = ({
  events,
  reload,
  setReload,
}) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const deleteEvent = async (code: any) => {
    const result = await Swal.fire({
      title: "Voulez-vous confirmer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Supprimer!",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      try {
        const res = await dispatch(deletePlaningItem(code));
        if (res) {
          Swal.fire({
            title: "Supprimé !",
            icon: "success",
          });
          setReload(!reload);
        }
      } catch (error) {
        console.error("Error deleting event:", error);
        Swal.fire({
          title: "Error!",
          text: "There was an issue deleting the event.",
          icon: "error",
        });
      }
    }
  };

  const eventsByMonth = Array.from({ length: 12 }, (_, month) => ({
    month,
    events: events.filter(
      (event) => event.year === currentYear && event.month === month
    ),
  }));
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState<any>(undefined);

  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <div>
      <PreventifeForm
        showModal={showModal}
        handleClose={handleClose}
        date={date}
        reload={reload}
        setReload={setReload}
      />
      <div style={styles.calendarGrid}>
        {eventsByMonth.map(({ month, events }) => (
          <div
            key={month}
            style={{
              ...styles.monthCell,
              border:
                month === currentMonth ? "2px solid #007bff" : "1px solid #ddd",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "15px",
              transition: "transform 0.3s",
              cursor: "pointer",
            }}
          >
            <h3
              style={{
                ...styles.monthTitle,
                textTransform: "capitalize",
                fontWeight: "bold",
                color: month === currentMonth ? "#007bff" : "#333",
              }}
              onClick={() => {
                setDate({ month: month + 1, year: currentYear });
                setShowModal(true);
              }}
            >
              {new Date(currentYear, month).toLocaleString("fr-FR", {
                month: "long",
              })}
            </h3>
            <hr style={{ border: "0.5px solid #ddd" }} />
            {events.length > 0 ? (
              <ul style={{ padding: 0 }}>
                {events.map((event) => (
                  <li
                    key={event.id}
                    style={{
                      ...styles.eventItem,
                      backgroundColor: event.color || "#e0f7fa",
                      borderRadius: "8px", // Rounded corners for a smoother look
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      cursor: "pointer", // Make the list item clickable
                      transition: "background-color 0.3s, transform 0.3s", // Smooth transition on hover
                      margin: "7px",
                    }}
                    title={event.description || "Aucun détail disponible"}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.05)")
                    } // Zoom effect on hover
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    } // Reset zoom effect
                    aria-label={event.description || "Event details"}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        window.location.href =
                          "/preventifChecklists/" +
                          event.data.code +
                          "/" +
                          event.data.machine_code;
                      }}
                    >
                      {event.title}
                    </p>
                    <i
                      className="fas fa-trash-alt text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        deleteEvent(event.data.code);
                      }}
                      aria-label="Delete event"
                    ></i>
                  </li>
                ))}
              </ul>
            ) : (
              <p
                style={{
                  ...styles.noEvents,
                  color: "#999",
                  fontStyle: "italic",
                }}
              >
                Aucun événement
              </p>
            )}

            {/* {events.length > 0 ? (
              <div style={styles.eventList}>
                {events.map((event) => (
                  <div
                    key={event.id}
                    style={{
                      background: event.color || "black",
                      padding: "8px",
                      borderRadius: "5px",
                      margin: "5px 0",
                    }}
                  >
                    <p style={{ margin: 0, fontWeight: "500" }}>
                      {event.title}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <p
                style={{
                  ...styles.noEvents,
                  color: "#999",
                  fontStyle: "italic",
                }}
              >
                Aucun événement
              </p>
            )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  calendarGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "16px",
    padding: "16px",
  },
  monthCell: {
    borderRadius: "8px",
    padding: "12px",
    textAlign: "center",
    cursor: "pointer",
    transition: "0.3s",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  },
  monthTitle: {
    fontSize: "1.2em",
    marginBottom: "8px",
    color: "#333",
  },
  eventList: {
    marginTop: "8px",
    fontSize: "0.9em",
  },
  eventCount: {
    fontSize: "0.9em",
    color: "#007bff",
    fontWeight: "bold",
    marginBottom: "8px",
    display: "block",
  },
  noEvents: {
    fontSize: "0.9em",
    color: "#777",
  },
};

export default YearlyCalendar;
