import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { MachineType } from "../../../Types/Entites/MachineType";
import { MachineDataType } from "../../../Types/Forms/MachineDataType";
import _ from "lodash";
import {
  InterventionObjetType,
  InterventionType,
  MouvementType,
} from "../../../Types/Entites/InterventionType";

export const findIntervention = createAsyncThunk(
  "interventions/findInterventions",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`interventions/findInterventions`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findMouvements = createAsyncThunk(
  "interventions/findMouvements",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`interventions/findMouvements`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findObjets = createAsyncThunk(
  "interventions/findobjets",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`interventions/findobjets`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findinterventionImages = createAsyncThunk(
  "interventions/findInterventionsImages",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`interventions/findInterventionsImages`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const groupsCount = createAsyncThunk(
  "interventions/groupesCount",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `interventions/groupesCount/${data.start}/${data.end}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const machinesInterventions = createAsyncThunk(
  "interventions/machineInterventionCount",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `interventions/machineInterventionCount/${data.start}/${data.end}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const technicienInterventions = createAsyncThunk(
  "interventions/interventionsTechnicien",
  async (date: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `interventions/interventionsTechnicien/${date}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Interventions {
  interventionsList: { [objet: string]: InterventionType[] };
  interventionsbyCode: { [code: string]: InterventionType[] };

  interventionsByDate: { [date: string]: any[] };
  interventionsByUser: { [user: string]: any[] };
  imagesGroupedByIntervention: { [intervention: string]: any[] };

  GroupsObjects: { [groupe: string]: InterventionObjetType[] };
  MouvementsByInterventions: { [code: string]: MouvementType[] };
  InterventionsParTickets: { [ticket: string]: InterventionType[] };
  CountGroups: any;
  machinesInterventions: any;
  technicienInterventions: any;
}

const initialState: Interventions = {
  interventionsList: {},
  interventionsByDate: {},
  GroupsObjects: {},
  interventionsByUser: {},
  MouvementsByInterventions: {},
  imagesGroupedByIntervention: {},
  InterventionsParTickets: {},
  interventionsbyCode: {},
  CountGroups: [],
  machinesInterventions: [],
  technicienInterventions: [],
};

export const interventionSlice = createSlice({
  name: "interventions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findIntervention.fulfilled, (state, action) => {
      const groupedByObjet = _.groupBy(action.payload, "objet_code");
      const groupedByDate = _.groupBy(action.payload, "date");
      const groupedByUser = _.groupBy(action.payload, "user_code");
      const groupedByTicket = _.groupBy(action.payload, "ticket_code");
      const groupedByCode = _.groupBy(action.payload, "code");

      return {
        ...state,
        interventionsList: groupedByObjet,
        interventionsByDate: groupedByDate,
        interventionsByUser: groupedByUser,
        InterventionsParTickets: groupedByTicket,
        interventionsbyCode: groupedByCode,
      };
    });
    builder.addCase(findObjets.fulfilled, (state, action) => {
      const groupedByGroups = _.groupBy(
        action.payload,
        "intervention_groupe_code"
      );

      return {
        ...state,
        GroupsObjects: groupedByGroups,
      };
    });
    builder.addCase(findinterventionImages.fulfilled, (state, action) => {
      const groupedByCodes = _.groupBy(action.payload, "code");

      return {
        ...state,
        imagesGroupedByIntervention: groupedByCodes,
      };
    });
    builder.addCase(findMouvements.fulfilled, (state, action) => {
      const groupedByIntervention = _.groupBy(action.payload, "numero");

      return {
        ...state,
        MouvementsByInterventions: groupedByIntervention,
      };
    });
    builder.addCase(groupsCount.fulfilled, (state, action) => {
      return {
        ...state,
        CountGroups: action.payload,
      };
    });
    builder.addCase(machinesInterventions.fulfilled, (state, action) => {
      return {
        ...state,
        machinesInterventions: action.payload,
      };
    });
    builder.addCase(technicienInterventions.fulfilled, (state, action) => {
      return {
        ...state,
        technicienInterventions: action.payload,
      };
    });
  },
});

export const ListInterventions = (state: RootState) =>
  state.interventions.interventionsList;
export const ListObjets = (state: RootState) =>
  state.interventions.GroupsObjects;
export const ListMouvements = (state: RootState) =>
  state.interventions.MouvementsByInterventions;
export const InterventionsPerDate = (state: RootState) =>
  state.interventions.interventionsByDate;
export const InterventionsPerUser = (state: RootState) =>
  state.interventions.interventionsByUser;
export const InterventionsImages = (state: RootState) =>
  state.interventions.imagesGroupedByIntervention;
export const InterventionParTicket = (state: RootState) =>
  state.interventions.InterventionsParTickets;
export const InterventionParCode = (state: RootState) =>
  state.interventions.interventionsbyCode;
export const interventionsGroupsCount = (state: RootState) =>
  state.interventions.CountGroups;
export const machineInterventionsCount = (state: RootState) =>
  state.interventions.machinesInterventions;
export const technicienInterventionsCount = (state: RootState) =>
  state.interventions.technicienInterventions;
export default interventionSlice.reducer;
