import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import {
  AffectationChecklistType,
  ChecklistGroupType,
  ChecklistType,
} from "../../../Types/Entites/ChecklistType";
import _ from "lodash";
import { TraveauxOdfType } from "../../../Types/Entites/TraveauxOdfType";
import { findKey } from "lodash";
import { findIndex } from "lodash";
import { ChecklistResultType } from "../../../Types/Entites/ChecklistResultType";

interface ChecklistData {
  data: any;
  groupe: string;
  id_groupe: string;
  maintenance: string;
}

export const saveChecklistItem = createAsyncThunk(
  "checklists/createChecklistItem",
  async (data: ChecklistData, { rejectWithValue }) => {
    try {
      const response = await axios.post("checklists/createChecklistItem", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const saveChecklistGroup = createAsyncThunk(
  "checklists/createGroupChecklist",
  async (data: ChecklistData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "checklists/createGroupChecklist",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const updateResult = createAsyncThunk(
  "checklists/updateResult",
  async ({ id, newData }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `checklists/updateResult/${id}`,
        newData
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findChecklistResult = createAsyncThunk(
  "checklists/findChecklistResult",
  async (machineCode: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `checklists/findChecklistResult/${machineCode}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findChecklistResultByPlaning = createAsyncThunk(
  "checklists/findChecklistResultByPlaning",
  async (planingCode: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `checklists/findChecklistResultByPlaning/${planingCode}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findAffectationChecklist = createAsyncThunk(
  "checklists/findAffectationChecklist",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`checklists/findAffectationChecklist`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findAllChecklists = createAsyncThunk(
  "checklists/findChecklists",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`checklists/findChecklists`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteChecklistItem = createAsyncThunk(
  "checklists/deleteChecklistItem",
  async ({ code }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get("checklists/deleteChecklistItem", code);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateChecklistItemApi = createAsyncThunk(
  "checklists/updateChecklistItemApi",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `checklists/updateChecklistItemApi/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findStatusOdf = createAsyncThunk(
  "checklists/findStatus",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`checklists/findStatus`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findChecklistsGroups = createAsyncThunk(
  "checklists/findChecklistsGroups",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`checklists/findChecklistsGroups`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface checklist {
  affectationChecklist: { [groupeMachine: string]: AffectationChecklistType[] };
  checklistsCuratifByGroupe: { [groupeChecklist: string]: ChecklistType[] };
  checklistsPreventifByGroupe: { [groupeChecklist: string]: ChecklistType[] };

  statusByOdf: { [odfCode: string]: TraveauxOdfType[] };
  groups: ChecklistGroupType[];
  ODFResults: { [checklistCode: string]: ChecklistResultType[] };
  PreventifResults: { [checklistCode: string]: ChecklistResultType[] };
  checklistDate: string;
  checklistTechnicien: string;
  rebutTL: number | null;
  rebutTech: number | null;
}

const initialState: checklist = {
  affectationChecklist: {},
  checklistsCuratifByGroupe: {},
  checklistsPreventifByGroupe: {},
  statusByOdf: {},
  groups: [],
  ODFResults: {},
  PreventifResults: {},
  checklistDate: "",
  checklistTechnicien: "",
  rebutTL: null,
  rebutTech: null,
};

export const checklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveChecklistItem.fulfilled, (state, action) => {
      if (action.payload.data[0]) {
        const data = action.payload.data[0];
        const groupe = data.groupe;

        return {
          ...state,
          checklistsCuratifByGroupe: {
            ...state.checklistsCuratifByGroupe,
            [groupe]: [
              ...(state.checklistsCuratifByGroupe[groupe] || []),
              ...action.payload.data,
            ],
          },
        };
      }
    });

    builder.addCase(findChecklistResult.fulfilled, (state, action) => {
      const groupedByChecklist = _.groupBy(action.payload, "checklist_code");
      const groupedBYRRole = _.groupBy(action.payload, "role_code");
      return {
        ...state,
        ODFResults: groupedByChecklist,
        rebutTech: groupedBYRRole["Technicien"]
          ? groupedBYRRole["Technicien"][0]?.rebut
          : "-",
        rebutTL: groupedBYRRole["TEAM LEADER"]
          ? groupedBYRRole["TEAM LEADER"][0]?.rebut
          : "-",
        checklistDate: action.payload[0]?.date.toString().split("T")[0],
      };
    });
    builder.addCase(findChecklistResultByPlaning.fulfilled, (state, action) => {
      console.log(action.payload);
      const groupedByChecklist = _.groupBy(action.payload, "checklist_code");

      return {
        ...state,
        PreventifResults: groupedByChecklist,
        checklistDate: action.payload[0]?.date.toString().split("T")[0],
        checklistTechnicien: action.payload[0]?.nom_user,
      };
    });
    builder.addCase(deleteChecklistItem.fulfilled, (state, action) => {});

    builder.addCase(findAllChecklists.fulfilled, (state, action) => {
      const groupedByMaintenance = _.groupBy(action.payload, "maintenance");

      const curatifGroupedByGroupe = _.groupBy(
        groupedByMaintenance["Curatif"],
        "groupe"
      );
      const preventifGroupedByGroupe = _.groupBy(
        groupedByMaintenance["Préventif"],
        "groupe"
      );
      return {
        ...state,
        checklistsCuratifByGroupe: curatifGroupedByGroupe,
        checklistsPreventifByGroupe: preventifGroupedByGroupe,
      };
    });
    builder.addCase(findAffectationChecklist.fulfilled, (state, action) => {
      const groupedByMachines = _.groupBy(action.payload, "groupe_machine");

      return {
        ...state,
        affectationChecklist: groupedByMachines,
      };
    });
    builder.addCase(findStatusOdf.fulfilled, (state, action) => {
      const groupedByOdf = _.groupBy(action.payload, "odf_code");

      return {
        ...state,
        statusByOdf: groupedByOdf,
      };
    });
    builder.addCase(findChecklistsGroups.fulfilled, (state, action) => {
      return {
        ...state,
        groups: action.payload,
      };
    });
    builder.addCase(updateChecklistItemApi.fulfilled, (state, action) => {
      console.log(action.payload);

      // Return original state if index is not found
      return state;
    });
  },
});

export const affectationChecklistByMachineGroup = (state: RootState) =>
  state.checklist.affectationChecklist;
export const checklistsCuratifGroupedByGroupe = (state: RootState) =>
  state.checklist.checklistsCuratifByGroupe;
export const checklistsPreventifGroupedByGroupe = (state: RootState) =>
  state.checklist.checklistsPreventifByGroupe;
export const Status = (state: RootState) => state.checklist.statusByOdf;
export const Groups = (state: RootState) => state.checklist.groups;
export const ChecklistResults = (state: RootState) =>
  state.checklist.ODFResults;

export const PreventifChecklistResults = (state: RootState) =>
  state.checklist.PreventifResults;
export const checklistTechnicien = (state: RootState) =>
  state.checklist.checklistTechnicien;
export const checklistDate = (state: RootState) =>
  state.checklist.checklistDate;
export const rebutTL = (state: RootState) => state.checklist.rebutTL;
export const rebutTech = (state: RootState) => state.checklist.rebutTech;

export default checklistSlice.reducer;
