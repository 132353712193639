import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import { Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { MachineType } from "../../_App/Types/Entites/MachineType";
import { updateMachineById } from "../../_App/Redux/Slices/machines/machineSlice";

export const ChangeMachineStatus = ({
  ShowModal,
  setShowModal,
  selectedMachine,
  setSelectedMachine,
}: any) => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    setSelectedMachine(undefined);
    setShowModal(false);
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MachineType>();

  useEffect(() => {
    if (selectedMachine) {
      reset(selectedMachine);
    }
  }, [selectedMachine, ShowModal]);

  const onSubmit: SubmitHandler<MachineType> = async (data) => {
    if (selectedMachine) {
      let newData = { ...data };

      try {
        dispatch(updateMachineById({ id: selectedMachine.code, data: data }));
        closeModal();
      } catch (error) {
        console.error("Error updating vehicule:", error);
      }
    }
  };
  return (
    <div>
      <Modal show={ShowModal} onHide={() => closeModal()}>
        <Modal.Header>
          <Modal.Title>
            <span className="modal-title" id="addCategorieLabel">
              {" "}
              {"Changer l'état de la machine " + selectedMachine}
            </span>
          </Modal.Title>
          <button
            type="reset"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              closeModal();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form id="addContactModalTitle" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row mb-2">
                    <div className="col-md-12">
                      <div className="contact-phone">
                        <i className="flaticon-telephone"></i>
                        <select
                          className="form-control"
                          {...register("status")}
                        >
                          <option
                            value={"En panne"}
                            selected={selectedMachine?.status === "En panne"}
                          >
                            {" "}
                            En panne
                          </option>
                          <option
                            value={"En production"}
                            selected={
                              selectedMachine?.status === "En production"
                            }
                          >
                            {" "}
                            En production
                          </option>
                          <option
                            value={"Démarrage en cours"}
                            selected={
                              selectedMachine?.status === "Démarrage en cours"
                            }
                          >
                            {" "}
                            Démarrage en cours
                          </option>
                          <option
                            value={"En arrêt"}
                            selected={selectedMachine?.status === "En arrêt"}
                          >
                            {" "}
                            En arrêt
                          </option>
                          <option
                            value={"En réparation"}
                            selected={
                              selectedMachine?.status === "En réparation"
                            }
                          >
                            {" "}
                            En réparation
                          </option>
                          <option
                            value={"En attente"}
                            selected={
                              selectedMachine?.status === "En attente"
                            }
                          >
                            {" "}
                            En attente
                          </option>
                        </select>
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="searchable-container modal-footer">
              <button
                className="btn btn-warning"
                data-dismiss="modal"
                type="reset"
                onClick={() => closeModal()}
              >
                {" "}
                <i className="flaticon-delete-1"></i> Annuler
              </button>

              <button type="submit" id="btn-add" className="btn btn-primary">
                Enregistrer
              </button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
};
