import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Form, Button } from "react-bootstrap";
import * as XLSX from "xlsx"; // Import xlsx
import { mtbf_mttr } from "../../_App/Redux/Slices/machines/machineSlice";

const RapportMTBF_MTTR = ({ setMergedData }: any) => {
  const rapport_mttr_mtbf = useAppSelector(mtbf_mttr);
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const machinesPerPage = 15;
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = rapport_mttr_mtbf?.machines?.filter((item: any) =>
    item.machine_code?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const indexOfLastMachine = currentPage * machinesPerPage;
  const indexOfFirstMachine = indexOfLastMachine - machinesPerPage;
  const currentMachines = filteredData?.slice(
    indexOfFirstMachine,
    indexOfLastMachine
  );
  const totalPages = Math.ceil(filteredData?.length / machinesPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const exportToExcel = () => {
    const dataToExport = filteredData?.map((machine: any) => ({
      machine: machine.machine_code,
      temps_de_reparation: machine.total_repairing_minutes,
      temps_de_fonctionnement: machine.total_functioning_minutes,
      nombre_pannes: machine.pannes_number,
      MTBF: machine.MTBF,
      MTTR: machine.MTTR,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "machines");
    XLSX.writeFile(workbook, "rapport_mtbf_mttr.xlsx");
  };

  return (
    <>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="col-10">
          {" "}
          <Form.Group controlId="formSearch">
            <Form.Control
              type="text"
              placeholder="Recherche par machine ..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          {" "}
          <Button
            variant="success"
            onClick={exportToExcel}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export
          </Button>
        </div>
      </div>

      <table id="style-3" className="table style-3 table-hover">
        <thead>
          <tr>
            <th>Machine</th>
            <th>Temps de fonctionnement</th>
            <th>Temps de réparation</th>
            <th>Nombre de pannes</th>
            <th>MTBF</th>
            <th>MTTR</th>
          </tr>
        </thead>
        <tbody>
          {currentMachines?.length > 0 ? (
            <>
              {currentMachines?.map((machine: any, index: any) => (
                <tr key={index}>
                  <td>{machine.machine_code}</td>
                  <td>{machine.total_functioning_minutes?.toFixed(2)}</td>
                  <td>{machine.total_repairing_minutes?.toFixed(2)}</td>
                  <td>{machine.pannes_number}</td>
                  <td>{machine.MTBF?.toFixed(2) || "-"}</td>
                  <td>{machine.MTTR?.toFixed(2) || "-"}</td>
                </tr>
              ))}

              {/* Calculate mean MTBF and MTTR */}
              {/* <tr>
                <td colSpan={4}>Moyenne</td>
                <td colSpan={1}>
                  {currentMachines
                    ?.filter((machine: any) => machine.MTBF)
                    .reduce(
                      (sum: number, machine: any) => sum + machine.MTBF,
                      0
                    ) /
                    currentMachines.filter((machine: any) => machine.MTBF)
                      .length || "-"}
                </td>
                <td colSpan={1}>
                  {currentMachines
                    ?.filter((machine: any) => machine.MTTR)
                    .reduce(
                      (sum: number, machine: any) => sum + machine.MTTR,
                      0
                    ) /
                    currentMachines.filter((machine: any) => machine.MTTR)
                      .length || "-"}
                </td>
              </tr> */}
              <tr>
                <td colSpan={4}>Moyenne</td>
                <td colSpan={1}>{rapport_mttr_mtbf.mean_MTBF?.toFixed(2)}</td>
                <td colSpan={1}>{rapport_mttr_mtbf.mean_MTTR?.toFixed(2)}</td>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={10}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination" style={{ overflow: "auto", margin: "5px" }}>
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className="btn"
        >
          Préc.
        </button>

        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            className={currentPage === i + 1 ? "btn active" : "btn"}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}

        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className="btn"
        >
          Suiv.
        </button>
      </div>
    </>
  );
};

export default RapportMTBF_MTTR;
